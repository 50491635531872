import { render, staticRenderFns } from "./chemReviewDetail.vue?vue&type=template&id=60e1fcaa"
import script from "./chemReviewDetail.vue?vue&type=script&lang=js"
export * from "./chemReviewDetail.vue?vue&type=script&lang=js"
import style0 from "./chemReviewDetail.vue?vue&type=style&index=0&id=60e1fcaa&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\NewDevelopment\\PROJECT\\DAEDONG-ESF\\FrontEnd\\DAEDONGESFFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60e1fcaa')) {
      api.createRecord('60e1fcaa', component.options)
    } else {
      api.reload('60e1fcaa', component.options)
    }
    module.hot.accept("./chemReviewDetail.vue?vue&type=template&id=60e1fcaa", function () {
      api.rerender('60e1fcaa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/mdm/mam/review/chemReviewDetail.vue"
export default component.exports